$icon-font-path: 'node_modules/font-awesome/fonts';

//Bootstrap
$enable-flex: true;

//urls
//$url_title: url("../images/title.svg");
$url_title: url("assets/images/title.svg");
$url_symbol: url("assets/images/symbol.svg");
$url_title_png: url("assets/images/logo.png");
//$homefig: url("assets/images/homefig.png");
$homefig: url("assets/images/diorama.png");
$wave1: url("assets/images/wave1.svg");
$wave2: url("assets/images/wave2.svg");
$wave3: url("assets/images/wave3.svg");
$img_torneira: url("assets/images/torneira.png");
$img_esgoto: url("assets/images/esgoto.svg");
$img_agua: url("assets/images/agua.svg");
$img_residuos: url("assets/images/residuos.svg");
$img_regulacao: url("assets/images/regulacao.svg");
$img_apoio: url("assets/images/apoio.svg");
$img_leau: url("assets/images/p_leau.png");
$img_cnpq: url("assets/images/p_cnpq.svg");
$img_funasa: url("assets/images/p_funasa.png");
$img_ufrj: url("assets/images/p_ufrj.png");
$img_prourb: url("assets/images/p_prourb.png");



//colors
$color_main_a: #bfc4b7;
//$color_main_b: #eac6ae;
$color_main_b: #D7D3BE;
$color_main_font: #6c5d53;
$color_main_active_font: #2b416a;
$color_thumBorder: #ababab;

$fonts_base_dir: 'shared/styles/fonts/';

//fonts
@font-face {
  font-family: 'nobel';
  src: url($fonts_base_dir+'dtlnobelt-webfont.eot');
  src: url($fonts_base_dir+'dtlnobelt-webfont.eot?#iefix') format('embedded-opentype'),
  url($fonts_base_dir+'dtlnobelt-webfont.woff2') format('woff2'),
  url($fonts_base_dir+'dtlnobelt-webfont.woff') format('woff'),
  url($fonts_base_dir+'dtlnobelt-webfont.ttf') format('truetype'),
  url($fonts_base_dir+'dtlnobelt-webfont.svg#dtl_nobelt_condensed_regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nobelcondensed';
  src: url($fonts_base_dir'dtlnobelt-condensed-webfont.eot');
  src: url($fonts_base_dir+'dtlnobelt-condensed-webfont.eot?#iefix') format('embedded-opentype'),
  url($fonts_base_dir+'dtlnobelt-condensed-webfont.woff2') format('woff2'),
  url($fonts_base_dir+'dtlnobelt-condensed-webfont.woff') format('woff'),
  url($fonts_base_dir+'dtlnobelt-condensed-webfont.ttf') format('truetype'),
  url($fonts_base_dir+'dtlnobelt-condensed-webfont.svg#dtl_nobelt_condensed_regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'abril_fatfaceregular';
  src: url($fonts_base_dir+'AbrilFatface-Regular-webfont.eot');
  src: url($fonts_base_dir+'AbrilFatface-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url($fonts_base_dir+'AbrilFatface-Regular-webfont.woff2') format('woff2'),
  url($fonts_base_dir+'AbrilFatface-Regular-webfont.woff') format('woff'),
  url($fonts_base_dir+'AbrilFatface-Regular-webfont.ttf') format('truetype'),
  url($fonts_base_dir+'AbrilFatface-Regular-webfont.svg#abril_fatfaceregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

$font_regular: 'nobel', Helvetica, Helvetica Neue, Verdana, sans-serif;
$font_condensed: 'nobelcondensed', Helvetica, Helvetica Neue, Verdana, sans-serif;
$font_abril: 'abril_fatfaceregular', serif;


