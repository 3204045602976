/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here node_modules/bootstrap/scss/_variables.scss
 */
$enable-flex : true;

@import 'variables';

@import '../../../node_modules/bootstrap/scss/bootstrap-flex';

$fa-font-path : '../../../node_modules/font-awesome/fonts';


.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

html,
body {
  height: 100%;
  max-width: 100%;
  /*  overflow: hidden;*/
}

body {
  background-color: $color_main_a;
  font-family: $font_regular;
  font-size: 13px;
}

a {
  outline: 0 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 1) {
  body {
    font-size: 13px;
  }
}

.container-fluid {
  height: 100%;
  max-width: 1920px;
  padding: 0px;
}


.svg .navbar-brand {
  background-image: $url_symbol;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;

}

.no-svg .sidebar .navbar .navbar-brand {
  width: 280px;
  height: 150px;
  background-image: $url_title_png;
  h1 {
    display: none;
  }
}

#pesquisa,
#consorcios,
#publicacoes,
#contato {
  //  display: none;
}

.content {
  // visibility: hidden;
  //background-color: $color_main_b;
  background-color: red;
  height: 100%;
  //@extend .col-sm-6;
  //@extend .col-md-7;
  padding-left: 0px;
  padding-right: 0px;

  @include media-breakpoint-down(lg) {
    width: 80%;
  }
  .texto {
    padding: 3em;
  }

  .contactForm {

    max-width: 400px;
    padding: 15px;

    input {
      width: 100%;
      max-width: 400px;
    }
    input[type="submit"] {
      max-width: 60px;
    }
  }
}

#waves {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 330px;
  /* background-image: $wave3, $img_esgoto, $img_torneira, $wave1, $wave2;
  background-position: 5px 260px, right bottom, left top, 10px 185px, 0 220px;
  background-repeat: repeat-x, no-repeat, no-repeat, repeat-x, repeat-x;*/

  background-image: $wave3, $wave1, $wave2;
  background-position: 5px 280px, 10px 205px, 0 240px;
  background-repeat: repeat-x;
}

.section-title{
  font-size: 2rem !important;
  color: $color_main_font !important;
}